import {AxiosError} from "axios";
import {useRequestAMLService} from "modules/Projects/shared/services/useRequestAMLService";
import {useCallback} from "react";
import {generateCompleteURL} from "utilities/generateCompleteURL/generateCompleteURL";
import {AMLTenantsAssessmentGroupsEndpoints} from "../endpoints";
import {IAssessmentGroup} from "../interfaces/IAssessmentGroup";

interface IUseRestoreAssessmentGroups {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly restoreAssessmentGroup: (
    assessment_group_id: string,
    cb: (assessmentGroup: IAssessmentGroup) => void
  ) => void;
}
interface IAssessmentGroupResponse {
  readonly data: IAssessmentGroup;
}

export const useRestoreAssessmentGroups = (): IUseRestoreAssessmentGroups => {
  const { IsLoading, RequestError, sendPatchRequest } = useRequestAMLService<IAssessmentGroupResponse>();

  const restoreAssessmentGroup = useCallback(
    (assessment_group_id: string, cb: (assessmentGroup: IAssessmentGroup) => void) =>
      sendPatchRequest(
        generateCompleteURL(
          AMLTenantsAssessmentGroupsEndpoints.assessment_groups.restore,
          {
            assessment_group_id: assessment_group_id
          },
          {},
        ),
        {},
        (assessmentGroup) => cb(assessmentGroup.data),
        {}
      ),
    [sendPatchRequest]
  );

  return {
    IsLoading,
    RequestError,
    restoreAssessmentGroup
  }
}